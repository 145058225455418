<template>
    <div class="grid grid-cols-12 h-screen place-items-center">
        <div class="col-span-1 desktopShow"></div>
        <div class="md:col-span-10 col-span-12 w-full bg-white rounded-2xl shadow-2xl max-w-[1080px]">
            <div class="flex justify-center items-center py-10 rounded-t-2xl">
                <!-- <h1 class="text-[50px] leading-none PattayaFont">CityBanana</h1> -->
                <!-- <h1 class="text-[20px]">登入</h1> -->
                <img class="w-[50%]" src="/img/logos/logo_type3.svg" alt="" />
            </div>
            <div class="flex justify-center items-center">
                <!-- <div class="flex-1 flex justify-center my-32">
                    <img class="w-[60%]" src="/img/logos/logo_type3.svg" alt="">
                </div> -->
                <div class="p-10 flex-1 bg-gradient-to-bl from-orange-500 to-yellow-400 rounded-b-lg">
                    <div class="max-w-[600px] mx-auto">
                        <el-form ref="form" :label-position="labelPosition" :model="form" status-icon :rules="rules">
                            <el-form-item prop="name">
                                <label class="text-gray-800 text-xl">
                                    帳號<span class="ml-2 text-orange-600">*</span>
                                    <el-input v-model="form.name" placeholder="請輸入帳號"></el-input>
                                </label>
                            </el-form-item>
                            <el-form-item prop="password">
                                <label class="text-gray-800 text-xl">
                                    密碼<span class="ml-2 text-orange-600">*</span>
                                    <el-input v-model="form.password" type="password" placeholder="請輸入密碼" autocomplete="off"></el-input>
                                </label>
                            </el-form-item>
                        </el-form>
                        <div class="flex justify-end">
                            <button
                                class="bg-black text-white rounded-lg py-2 max-w-[100px] flex-1 hover:scale-125 transform cursor-pointer transition duration-500"
                                type="submit"
                                @click.prevent="onSubmit('form')">
                                登入
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
// 使用 swwetalert2 套件
import Swal from "sweetalert2";
// 導入 vuex
import { mapState, mapActions, mapMutations, mapGetters } from "vuex";
// 導入 localStorage 設定值方法
import { setStorage } from "../../service/localStorage";
export default {
    computed: {
        ...mapState("i18nStore", { locale: "locale" }),
        ...mapGetters("i18nStore", ["langs"]),
        showLang() {
            switch (this.locale) {
                case "en":
                    return "us";

                default:
                    return this.locale;
            }
        },
    },
    data() {
        return {
            labelPosition: "left",
            form: {
                name: "",
                password: "",
            },
            rules: {
                name: [
                    {
                        required: true,
                        // validator: UserRule.FormUserAccountRule,
                        trigger: "blur",
                        message: "請輸入帳號",
                    },
                ],
                password: [
                    {
                        required: true,
                        // validator: UserRule.FormPasswordRule,
                        trigger: "blur",
                        message: "請輸入密碼",
                    },
                ],
            },
            lang: null,
        };
    },
    methods: {
        ...mapActions("userStore", ["getLoginUserInfo"]),
        ...mapMutations("userStore", ["setIsAuth", "setUser"]),
        ...mapActions("i18nStore", ["getLang", "getErrorMessagesList"]),
        ...mapActions("chatStore", ["getFirebaseToken"]),
        async onSubmit(formName) {
            try {
                await this.$refs[formName].validate();
                try {
                    const { data } = await this.$api.LoginApi(this.form);
                    await Swal.fire({
                        toast: true,
                        position: "top-end",
                        icon: "success",
                        title: "登入成功",
                        showConfirmButton: false,
                        timer: 1500,
                    });
                    // 設定 token 到 localStorage
                    setStorage("token", "Bearer " + data.access_token);
                    // 設定使用者資料
                    this.setUser(data.user);
                    this.getLoginUserInfo();
                    // 設定使用者為登入狀態
                    this.setIsAuth(true);
                    // 取得firebase token 以及登入 firebase auth
                    await this.getFirebaseToken(process.env.VUE_APP_SERVICE_CHAT_ID);
                    // 設定重取 api token 時間
                    setStorage("tokenRefreshTime", this.$moment().add(7, "days").valueOf());
                    // 設定重取 firebase token 時間
                    setStorage("firebaseTokenRefreshTime", this.$moment().add(30, "minutes").valueOf());
                    await this.getErrorMessagesList();
                    this.$router.push({ name: "dashboard" }).catch((e) => {
                        console.log(e);
                    });
                } catch (err) {
                    console.log(err);
                    Swal.fire({
                        position: "top-end",
                        toast: true,
                        icon: "error",
                        title: "登入失敗",
                        showConfirmButton: false,
                        timer: 1500,
                    });
                }
            } catch (err) {
                this.$message({
                    type: "error",
                    message: "表單驗證失敗",
                });
            }
        },
    },
};
</script>
<style lang="scss" scoped>
/deep/ .el-card__header {
    @apply bg-gradient-to-bl from-blue-300 to-blue-100;
}
/deep/ .el-form-item__label {
    color: theme("colors.blue.200");
}
/deep/ .el-card.is-always-shadow {
    box-shadow: 0 0 20px theme("colors.blue.200");
}
</style>
